/* Message.css */
.message {
  display: flex;
  justify-content: flex-start;
  margin: 8px;
  max-width: 100%; /* Ensure messages don't overflow the container */
}

.message.user {
  justify-content: flex-end;
}

.message-bubble {
  padding: 15px;
  border-radius: 8px;
  /* max-width: 75%; */
  word-wrap: break-word;
}

.message-bubble.user {
  background-color: #BBDEFB; /* Light mode user message */
  color: #000000; /* Light mode user text */
}

.message-bubble.user.dark {
  background-color: #0277BD; /* Dark mode user message */
  color: #ffffff; /* Dark mode user text */
}

.message-bubble.bulby {
  background-color: #FFF9C4; /* Light mode Bulby message */
  color: #000000; /* Light mode Bulby text */
}

.message-bubble.bulby.dark {
  background-color: #FFF59D; /* Dark mode Bulby message */
  color: #000000; /* Dark mode Bulby text */
}

.message-content ol, .message-content ul {
  margin: 0;
  padding-left: 20px;
  display: list-item;
}

.message-content li {
  margin-bottom: 5px;
  list-style-type: square;
}

.message-content p {
  margin: 7px 0;
}

.message-content li p {
  display: block;
  float: none;
}

.message-content a {
  color: rgb(255, 194, 17);
  font-weight: bold;
  text-decoration: underline;
}